import Heading from '@Src/components/ui/Heading';
import React, { ReactElement, useContext, useEffect, useState } from 'react';
import Text from '@Src/components/Text';
import * as styles from './index.module.scss';
import { useApp, usePageTheme } from '@Src/context/AppContext';
import cx from 'classnames';
import ContentLayout, { ContentLayoutContext } from '@Src/components/sitestructure/ContentLayout';
import Input from '@Src/components/ui/Input';
import { submitMerchInterest } from '@Src/actions/aloaded';
import { SubmitButton } from '@Src/components/ui/SubmitButton';
import { StripedHeading } from '@Src/components/ui/StripedHeading';
import { ParallaxItem } from '@Src/components/ParallaxItem';
import { ScrollAnchor } from '@Src/components/ui/ScrollAnchor';
import Link from '@Src/components/ui/Link';
import { SnappyContainerRow } from '@Src/components/ui/SnappyContainer';
import { ParallaxVideo } from '@Src/components/ui/ParallaxVideo';
import { HoverImage } from '@Src/components/ui/HoverImage';
import { CenterContainer } from '@Src/components/ui/CenterContainer';
import { graphql } from 'gatsby';
import { IBlock, IMerchOnDemandPageData, IPageData, IPageProps } from '@Src/types/ContentfulTypes';
import SEO from '@Src/components/SEO';
import ContentfulModule from '@Src/components/ContentfulModule';
import ContentfulImage from '@Src/components/ContentfulImage';
import { CSSTransition } from "react-transition-group";
import { createPortal } from 'react-dom';
import { QueryParams } from '@Src/types/models';

const portalRoot = typeof document !== `undefined` ? document.body : null

export interface Props extends IPageProps {
  data: {
      contentfulPageTypeMerchOnDemand: IMerchOnDemandPageData;
  };
}

export function MerchPage({ data, location, transitionStatus, pageContext }: Props) {
  console.log(data)
  const { title, subTitle, summary, image, seoSettings, content } = data.contentfulPageTypeMerchOnDemand;
  const { setTheme: setPageTheme } = usePageTheme();
  const { mode, pageType, setPageType, setPageTitle } = useApp();
  const [localTransStatus, setLocalTransStatus] = useState<string>(transitionStatus);
  const [name, setName] = useState('First Last');
  const [email, setEmail] = useState('');
  const [isSubmitting, setSubmitting] = useState(false)
  const [isSubmitted, setSubmitted] = useState(false)
  const [isError, setError] = useState(false)
  const [showBottomCTA, setShowBottonCTA] = useState(false)
  const [queryParams, setQueryParams] = useState<QueryParams>({});

  const handleSubmit = (event: any) => {
    event.preventDefault()
    if (!event.target.checkValidity()) {
      return
    }
    setSubmitting(true)
    submitMerchInterest(
      name,
      email,
      queryParams
    ).then(result => {
      // @ts-ignore
      if (window?.dataLayer instanceof Array) {
        // @ts-ignore
        window.dataLayer.push({
          event: 'submit_merch_interest',
          type: 'event',
          eventCallback: () => {
            setSubmitting(false)
            setSubmitted(true)
          },
          eventTimeout: 2000
        })
      }
    }).catch(e => {
      console.error(e)
      setSubmitted(false)
      setError(true)
    })
    return false;
  } 
	useEffect(() => {
		setPageType('signup');
    let query = new URLSearchParams(window.location.search);
    const newQueryParams = {
      utm_campaign: query.get('utm_campaign'),
      utm_source: query.get('utm_source'),
      utm_medium: query.get('utm_medium'),
      utm_content: query.get('utm_content'),
      fbclid: query.get('fbclid')
    }
    setQueryParams(newQueryParams)
	}, []);
  useEffect(() => {
    if (transitionStatus == 'entering' || transitionStatus == 'entered') {
      setPageTheme('dark');
      setPageType('signup');
    }
  }, [transitionStatus]);

  useEffect(() => {
      if (transitionStatus == 'entered') {
        setPageTheme('dark');
      }
  }, [mode]);

  const handlePass = (passed: boolean) => { 
    setPageTheme(passed ? 'light' : 'dark')
    setShowBottonCTA(passed)
  }
 
  return (
    <ContentLayout fullWidth={true} transitionState={transitionStatus} type="signup">
      <SEO
        location={location.pathname}
        title={seoSettings?.title || title}
        description={seoSettings?.description}
        meta={seoSettings?.metaTags}
        image={seoSettings?.image}
      />
      <ContentLayoutContext.Consumer>
        {({scrollY}) => (
          <>
            <div style={{ padding: 0, marginTop: '-0pt'}}>
              <div style={{display: 'flex', flexDirection: 'column',  alignItems: 'center', justifyContent: 'center'}}>
                <a name="signup" />
                <StripedHeading>{title}</StripedHeading>
                <div style={{marginTop: 30, marginBottom: 30}}>
                  <Text theme={mode} body={summary} />
                </div>
                {isSubmitted ?
                  <Text theme={mode} htmlText={`Access requested successfully! Thanks!`} />
                :
                  <form style={{opacity: isSubmitted ? 0.5 : 1}} className={styles.ctaForm} method="POST" onSubmit={handleSubmit}>
                   <fieldset className={styles.ctaFieldset}>
                      <div style={{border: ' 1px solid white', background: 'white', paddingTop: 10, paddingLeft: 10, margin: 0}}>
                        <Input
                          inputType="email"
                          required={true}
                          theme={"light"}
                          label="Your e-mail"
                          pure={true}
                          onUpdate={(value) => setEmail(value)}
                          style={{padding: 0}}
                        />
                      </div>
                      <SubmitButton
                        buttonType="submit"
                        onClick={() => {}}
                        theme={"light"}
                        yellow={true}
                        isLoading={false}
                        isConfirm={false}
                        isSubmitting={isSubmitting}
                        isItalic={true}
                        isUnderlined={true}
                        style={{fontStyle: 'italic', padding: 0, paddingLeft: '15pt', paddingRight: '15pt', margin: 0}}
                        label={isSubmitted ? 'Sucessfully submitted' : isSubmitting ? 'Requesting access' : 'Request access'}
                      />
                    </fieldset>
                  </form>
                }
                {image && <ContentfulImage file={image.file} style={{marginTop: 30, marginBottom: 30, width: 'calc(100% - 0pt)'}} />}
              </div>
              <br />
              <ScrollAnchor scrollY={scrollY} onPass={handlePass} />
              <div className={styles.root}>
                <div className={styles.grid2}>
                  {content &&
                    content.map(
                      (module: IBlock, index: number): ReactElement => (
                        module ? <ContentfulModule key={module.id} type={module.type} data={module} /> : <></> 
                      )
                    )}
                  {portalRoot && createPortal( 
                    <> 
                        <div className={cx(styles.ctaRow, showBottomCTA && styles.ctaRowVisible)}>
                          <SubmitButton
                            style={{display: 'unset', width: 'auto', border: '1px solid black', boxShadow: '0 10pt 13pt rgba(225, 255, 0, .5)'}}
                            yellow={true}
                            theme={'light'}
                            //theme={mode === 'light' ? 'dark' : 'light'} 
                            onClick={() => {window.location.href = '#signup'}} 
                            label={`Get Started with Merch On Demand Today`}
                          />
                        </div> 
                    </>,
                    portalRoot
                  )}
                </div>
              </div>
            </div>
            <div className={styles.footer} style={{padding: '20pt', fontSize: 18, flexDirection: 'column', display: 'flex', gap: 10}}> 
              <Text theme={mode} body={`Merch on demand is a new trend in the world of fashion and apparel. It allows customers to create custom clothing and accessories with their own designs or logos. This type of service is gaining popularity because it allows individuals to express their unique style and personality through their clothing`} />
              <Text theme={mode} body={`With merch on demand, customers can choose from a variety of products, including t-shirts, hats, bags, and more. They can then use online design tools to create their own designs or upload their own logos and images. The finished product is then printed and shipped directly to the customer. One of the key benefits of merch on demand is that it allows for small-scale production. This means that customers can order just a few items at a time, rather than having to purchase large quantities of a single design. This is perfect for individuals or small businesses who want to create personalized items without having to invest in a large inventory`} />
              <Text theme={mode} body={`Another benefit of merch on demand is that it allows for quick turnaround times. Customers can design and order their items in just a few minutes, and the finished products are usually shipped within a few days. This is a major advantage over traditional clothing production, which can take weeks or even months to complete`} />
              <Text theme={mode} body={`Overall, merch on demand is a convenient and cost-effective way for individuals and small businesses to create custom clothing and accessories. With a wide range of products and easy-to-use design tools, it has never been easier to express your unique style and personality through your clothing.`} />
            </div>
            <div className={styles.footer} style={{padding: '20pt', fontSize: 18, flexDirection: 'column', display: 'flex', gap: 10}}>
              <Text theme={mode}>Company details:</Text>
              <Text theme={mode}>ALOADED AB</Text>
              <Text theme={mode}>Bondegatan 21</Text>
              <Text theme={mode}>116 33 Stockholm</Text>
              <Text theme={mode}>Sweden</Text>
              <Text theme={mode}>Company Approved for Company Tax (Godkänd för F-skatt) with national company registration number: 559124-3836 VAT SE559124383601</Text>
            </div>
          </>
        )}
      </ContentLayoutContext.Consumer>
    </ContentLayout>
  )
}

export default MerchPage;

export const query = graphql`
	query ($id: String!) {
		contentfulPageTypeMerchOnDemand(id: { eq: $id }) {
      ... on IMerchOnDemandPageNode {
				...PageFields
				content {
					... on IBlockNode {
						...AllBlocks
					}
				}
        ...MerchOnDemandPageFields
			}
    }
	}
`;
